import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { setDateRange, fetchAnalyticsData } from '../../store/features/analytics/analyticsAction';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Switch } from "../../components/ui/switch";
import { useMediaQuery } from 'react-responsive';

const BarChartComp = () => {
  const [openDateRange, setOpenDateRange] = useState(false);
  const { isDark } = useSelector((state) => state.common);
  const [showLikes, setShowLikes] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const { impression, userId, dateRange } = useSelector((state) => state.analytics);
  const userinfo = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 1188px)' });

  useEffect(() => {
    if (userId && dateRange) {
      const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
      const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);
      dispatch(fetchAnalyticsData(userId, formattedStartDate, formattedEndDate));
    }
  }, [userId, dateRange, dispatch]);

  const handleSelect = (ranges) => {
    dispatch(setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    }));
  };

  const handleApply = () => {
    setShowComments(true);
    setShowLikes(true);
    setOpenDateRange(false);
  };

  const handleClick = () => {
    setOpenDateRange(!openDateRange);
  };

  const formatData = (data, showLikes, showComments) => {
    const comments = data.comments || [];
    const likes = data.likes || [];

    const combined = [
      ...(showComments ? comments : []),
      ...(showLikes ? likes : []),
    ];
    const result = [];
    combined.forEach((item) => {
      const existing = result.find((r) => r.name === item.date.slice(0, 10));
      if (existing) {
        existing.value += item.count;
      } else {
        result.push({ name: item.date.slice(0, 10), value: item.count });
      }
    });
    result.sort((a, b) => new Date(a.name) - new Date(b.name));
    return result;
  };

  const data1 = formatData(impression, showLikes, showComments);
  const maxValue = Math.max(...data1.map((entry) => entry.value));
  const yAxisDomain = [0, maxValue + 10];

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const predefinedRanges = [
    {
      label: "Previous Week",
      range: () => {
        const startDate = new Date(
          new Date().setDate(new Date().getDate() - 13)
        );
        const endDate = new Date(new Date().setDate(new Date().getDate() - 7));
        return { startDate, endDate };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Previous Month",
      range: () => {
        const startDate = new Date(
          new Date().setMonth(new Date().getMonth() - 1, 1)
        );
        const endDate = new Date(new Date().setDate(0));
        return { startDate, endDate };
      },
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Last 3 Months",
      range: () => ({
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
    {
      label: "Custom",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          range.startDate.getTime() === definedRange.startDate.getTime() &&
          range.endDate.getTime() === definedRange.endDate.getTime()
        );
      },
    },
      {
    label: "Life Time",
    range: () => {
      const startDate = new Date(userinfo.createdAt);
      const endDate = new Date(); // current date
      return { startDate, endDate };
    },
    isSelected(range, createdAt) {
      const definedRange = this.range(createdAt);
      return (
        range.startDate.getTime() === definedRange.startDate.getTime() &&
        range.endDate.getTime() === definedRange.endDate.getTime()
      );
    },
  }
  ];

  return (
    <div className="flex flex-col gap-4">
      <div
        className={`${
          isDark ? "text-black" : "text-gray-300"
        } justify-between flex`}
      >
        <h3 className="text-xl">Overall Stats Tracking</h3>
        <div className="flex flex-col justify-end items-end gap-4 text-sm cursor-pointer">
          <span
            className={`${
              isDark ? "border-black" : "border-white"
            } flex gap-2 border-[1px]  p-2 rounded-lg`}
            onClick={handleClick}
          >
            {formatDate(dateRange.startDate)} - {formatDate(dateRange.endDate)}
            <img
              src={`${
                isDark ? "/images/calenderBlack.svg" : "/images/calender.svg"
              }`}
              alt="calendar"
            />
          </span>
          {openDateRange && (
            <div
              className={`absolute top-[300px] z-10 flex flex-col items-end ${
                isDark ? "bg-white" : "bg-black"
              }`}
            >
              <DateRangePicker
                ranges={[{ startDate: dateRange.startDate, endDate: dateRange.endDate, key: 'selection' }]}
                onChange={handleSelect}
                className={`rdrp z-10 ${isDark ? "rdrp" : "rdrp-dark"}`}
                staticRanges={predefinedRanges}
                maxDate={new Date()}
              />
              <button
                className={`my-2 mx-3 gradient-button-new p-2 px-3 rounded-md text-white`}
                onClick={handleApply}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          isDark ? "bg-white" : "bg-[#2D2D2D]"
        } rounded-lg flex justify-center items-center`}
      >
        {impression.likes.length === 0 && impression.comments.length === 0 && impression.commentsOnComments.length === 0 ? (
          // <p className="text-center p-20">No Data to Show</p>
          <img style={{ height: "500px" }} src="https://static.vecteezy.com/system/resources/previews/007/872/974/non_2x/file-not-found-illustration-with-confused-people-holding-big-magnifier-search-no-result-data-not-found-concept-can-be-used-for-website-landing-page-animation-etc-vector.jpg"></img>
        ) : (
          <div className="flex flex-col gap-2 justify-center items-center">
            {isMobile ? (
              <ResponsiveContainer width="106%" height={400} className='text-[12px]'>
                <BarChart className="pt-6" width={1100} height={400} data={data1}>
                  <defs>
                    <linearGradient id="barGradient" x1="0" y1="1" x2="1" y2="0">
                      <stop offset="0.2" stopColor="#39B2FF" stopOpacity={1} />
                      <stop offset="1" stopColor="#8A51FF" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    axisLine={{ stroke: "#697381" }}
                    tickLine={false}
                  />
                  <YAxis
                    domain={yAxisDomain}
                    axisLine={{ stroke: "#697381" }}
                    tickLine={false}
                    tickFormatter={(value) => `${value}`}
                    tick={{ fill: isDark ? "black" : "white" }}
                  />
                  <CartesianGrid
                    horizontal={true}
                    vertical={false}
                    stroke="#697381"
                  />
                  <Bar
                    dataKey="value"
                    radius={6}
                    fill="url(#barGradient)"
                    maxBarSize={100}
                    className="tooltipClass"
                    title={(props) => props.value}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <BarChart className="pt-6" width={1100} height={400} data={data1}>
                <defs>
                  <linearGradient id="barGradient" x1="0" y1="1" x2="1" y2="0">
                    <stop offset="0.2" stopColor="#39B2FF" stopOpacity={1} />
                    <stop offset="1" stopColor="#8A51FF" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  axisLine={{ stroke: "#697381" }}
                  tickLine={false}
                />
                <YAxis
                  domain={yAxisDomain}
                  axisLine={{ stroke: "#697381" }}
                  tickLine={false}
                  tickFormatter={(value) => `${value}`}
                  tick={{ fill: isDark ? "black" : "white" }}
                />
                <CartesianGrid
                  horizontal={true}
                  vertical={false}
                  stroke="#697381"
                />
                <Bar
                  dataKey="value"
                  radius={6}
                  fill="url(#barGradient)"
                  maxBarSize={100}
                  className="tooltipClass"
                  title={(props) => props.value}
                />
              </BarChart>
            )}
        <div className="py-4 flex gap-4 md:gap-8">
  <div className={`flex items-center gap-1 md:gap-3 px-2 py-2 rounded-lg shadow-sm ${!isDark ? 'bg-gray-600' : 'bg-gray-100'}`}>
    <Switch
      checked={showLikes}
      onCheckedChange={() => {
        if (!showComments) setShowComments(true);
        setShowLikes(!showLikes);
      }}
      className={`${
        !isDark ? 'bg-gray-500' : 'bg-blue-500'
      } data-[state=checked]:${!isDark ? 'bg-gray-300' : 'bg-blue-700'} transition-colors duration-300`}
    />
    <p className={`${!isDark ? 'text-white' : 'text-gray-700'} ml-2`}>
      Likes
    </p>
  </div>
  <div className={`flex items-center gap-1 md:gap-3 px-2 py-2 rounded-lg shadow-sm ${!isDark ? 'bg-gray-600' : 'bg-gray-100'}`}>
    <Switch
      checked={showComments}
      onCheckedChange={() => {
        if (!showLikes) setShowLikes(true);
        setShowComments(!showComments);
      }}
      className={`${
        !isDark ? 'bg-gray-500' : 'bg-blue-500'
      } data-[state=checked]:${!isDark ? 'bg-gray-300' : 'bg-blue-700'} transition-colors duration-300`}
    />
    <p className={`${!isDark ? 'text-white' : 'text-gray-700'} ml-2`}>
      Comments
    </p>
  </div>
</div>



          </div>
        )}
      </div>
    </div>
  );
};

export default BarChartComp;
