import React, { useState, useEffect } from 'react';
import { getApi } from '../../../api/methods';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Subscription = (props) => {

  const navigate = useNavigate();
  const planarr = ['Monthly', 'Yearly'];
  const [getsubscription, setSubscription] = useState([]);
  const [activePlan, setActivePlan] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(planarr[activePlan]);
  const { isDark } = useSelector(state => state.common);

  // Function to fetch and set the subscription list
  const getSubList = () => {
    getApi('subscription/viewsubscription')
      .then((res) => {
        setSubscription(res.data);
      })
      .catch((err) => {
        console.error('Error fetching subscription list:', err);
      });
  }

  // Function to handle proceeding to the order review page
  const handleProceed = (item) => {
    const data = item.cost.find((ev) => ev.durationType === selectedPlan);
    const cartData = {
      ...item,
      cost: data.amount,
      planDuration: selectedPlan,
    };

    localStorage.setItem('cartdata', JSON.stringify(cartData));
    props.setIsShowSubs(false);
    navigate('/order-review');
  }

  // Function to render monthly subscription plans
  const getMonthlyPlan = () => {
    return getsubscription?.map((item, index) => {
      const isCenterCard = index === Math.floor(getsubscription?.length / 2);
      let planDescription;

      switch (item.planType) {
        case 'Pro':
          planDescription = 'Begin producing top-performing content daily while spending 80% less time.';
          break;
        case 'Creator':
          planDescription = 'Harness the potential of AI-crafted posts, and team insights and organic engagement.';
          break;
        case 'Growth':
          planDescription = 'Perfect for managing large-scale accounts, offering tailored strategies and insights for optimal growth.';
          break;
        default:
          planDescription = 'Build momentum on social media';
      }

      return (
        <div className={`col-lg-4 ${isDark ? '' : 'sub-color-dark'}`} key={index}>
          <div 
            className={`card-container ${isCenterCard ? "card-container-active1" : ""}`}
            onMouseEnter={(e) => e.currentTarget.classList.add('card-hover')}
            onMouseLeave={(e) => e.currentTarget.classList.remove('card-hover')}
          >
            <h4>{item.planType}</h4>
            <p style={{ color: 'black' }}>{planDescription}</p>
            <div><span>{item.currencyType}{selectedPlan === 'Monthly' ? item.cost[0].amount : item.cost[1].amount}</span> /Month</div>
            <hr />
            <h5 className='mb-2'>Included :</h5>
            <ul type="none">
              {item.features?.map((feature, featureIndex) => {
                return (
                  <li key={featureIndex}>
                    <i className="fa fa-check" aria-hidden="true"></i>
                    <span className={`${isDark ? '' : 'sub-color-dark'}`}>{feature}</span>
                  </li>
                );
              })}
            </ul>
            <button onClick={() => handleProceed(item)}>Proceed To Buy</button>
          </div>
        </div>
      );
    });
  }
  
  useEffect(() => {
    getSubList();
  }, []);

  const handlePlanClick = (index) => {
    setActivePlan(index);
    setSelectedPlan(planarr[index]);
  };

  return (
    <>
      <div className="container mt-2">
        <div className="row">
          <div className="col-6 mx-auto text-center subs-header">
            <h2>Buy Subscription</h2>
            <p className='mb-3'>From personal use to small businesses to enterprises. There's a Superhero for everyone!</p>
          </div>
          <div className="col-12">
            <div className={`toggle-plan ${isDark ? '' : 'sub-color-dark'}`}>
              {planarr?.map((item, index) => (
                <span
                  key={index}
                  onClick={() => handlePlanClick(index)}
                  className={activePlan === index ? 'active-plan' : ''}
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
          <div className={`col-lg-12 mx-auto ${isDark ? '' : 'sub-color-dark'}`}>
            <div className="row card-row">
              {getMonthlyPlan()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
