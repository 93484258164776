import axios from "axios";
import { axiosInstance } from "../../../api/API";
export const fetchAnalyticsData = (userId, startDate, endDate) => async (dispatch) => {
  try {
    // To include the end date fully, set end time to the end of the day.
    const adjustedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999)).toISOString();
    
    const [summaryResponse, postsResponse, engagementResponse, dailyAnalyticsResponse] = await Promise.all([
      axiosInstance.get(`/post-analytics/user/${userId}/posts/summary/timeframe?startTime=${startDate}&endTime=${adjustedEndDate}`),
      axiosInstance.get(`/post-analytics/user/${userId}/posts/data/timeframe?startTime=${startDate}&endTime=${adjustedEndDate}`),
      axiosInstance.get(`/post-analytics/user/${userId}/posts/engagement/timeframe?startTime=${startDate}&endTime=${adjustedEndDate}`),
      axiosInstance.get(`/post-analytics/posts/daily-analytics/timeframe?userId=${userId}&startTime=${startDate}&endTime=${adjustedEndDate}`)
    ]);
    
    // console.log('sss');
    // console.log(summaryResponse, postsResponse, engagementResponse, dailyAnalyticsResponse);
    // console.log('sss');
    
    dispatch({ type: "SET_ANALYTICS_DATA", payload: {
      summary: summaryResponse.data,
      posts: postsResponse.data,
      engagement: engagementResponse.data,
      dailyAnalytics: dailyAnalyticsResponse.data
    }});

    // Update other relevant data
    dispatch(totalLikesAndComments(userId));
    dispatch(allPosts(userId));
    dispatch(impressionTotal(userId, startDate, adjustedEndDate));
  } catch (error) {
    console.error("Error fetching analytics data:", error);
    dispatch({ type: "SET_ANALYTICS_ERROR", payload: error.message });
  }
};
export const totalLikesAndComments = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/detailed-list-by-userId/${id}`)
    .then((res) => {
      dispatch({ type: "TOTAL_LC", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "TOTAL_LC", payload: err });
    });
};

export const userProfileData = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/get-linkedin-profile/${id}`)
    .then((res) => {
      dispatch({ type: "PROFILE", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "PROFILE", payload: err });
    });
};

export const reactionSummary = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`/post-analytics/post/${postId}/reaction-summaries`)
    .then((res) => {
      dispatch({ type: "REACTION", payload: res });
      console.log(postId);
    })
    .catch((err) => {
      dispatch({ type: "REACTION", payload: err });
      console.log(postId);
    });
};

export const dailyPostTracker = (id) => async (dispatch) => {
  await axiosInstance
    .get(`daily-post-tracker/count/${id}`)
    .then((res) => {
      dispatch({ type: "POST_TRACKER", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "POST_TRACKER", payload: err });
    });
};

export const allPosts = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/get-all-post-analytics-by-userId/${id}`)
    .then((res) => {
      dispatch({ type: "ALL_POST", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "ALL_POST", payload: err });
    });
};

export const allComments = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/post/${postId}/get-all-comments`)
    .then((res) => {
      dispatch({ type: "ALL_COMMENTS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "ALL_COMMENTS", payload: err });
    });
};

export const impressionTotal =
  (userId, startTime, endTime) => async (dispatch) => {
    await axiosInstance
      .get(
        `post-analytics-daily/user/custom/${userId}?start=${startTime}&end=${endTime}`
      )
      .then((res) => {
        dispatch({ type: "IMPRESSION", payload: res });
        console.log(userId, startTime, endTime);
      })
      .catch((err) => {
        dispatch({ type: "IMPRESSION", payload: err });
      });
  };

export const postAnalytics = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/post/${postId}`)
    .then((res) => {
      dispatch({ type: "POST_ANALYTICS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "POST_ANALYTICS", payload: err });
    });
};

export const getTeamMembers = (teamId) => async (dispatch) => {
  await axiosInstance
    .get(`invite/get-team-members-by-teamid/${teamId}`)
    .then((res) => {
      dispatch({ type: "TEAM_MEMBERS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "TEAM_MEMBERS", payload: err });
    });
};

export const setUserId = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_USER_ID", payload: data });
  };
};


export const setDateRange = (dateRange) => ({
  type: "SET_DATE_RANGE",
  payload: dateRange
});