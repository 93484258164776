import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import BarChartComp from "./BarChartComp";
import PostSummeryBox from "../../components/Dashboard/PostSummeryBox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import TopInteractors from "./TopInteractors";
import StreakComp from "./StreakComp";
import Table from "./Table";
import {
  setUserId,
  totalLikesAndComments,
  getTeamMembers,
  fetchAnalyticsData,
} from "../../store/features/analytics/analyticsAction";
import { changeLayoutShift } from "../../store/features/common/commonSlice";
import { Link } from "react-router-dom";
import { getApi } from "../../api/methods";

const Analytics = () => {
  const { layoutShift, isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);
  const { dateRange, team } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();
  const [teamMember, setTeamMember] = useState("");
  const [isFree, setIsFree] = useState(false);

  // Log out relevant props to debug
  console.log("userData:", userData);
  console.log("team:", team?.data?.users);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const transactionData = await getApi(
          `transaction/get-subscription-by-id/${userData.teamId}`
        );
        if (transactionData.length > 0 && transactionData[0].plan_Name === "Free-Tier") {
          setIsFree(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }
    };

    checkSubscription();
  }, [userData.teamId]);

  useEffect(() => {
    // Fetch team members when component mounts
    dispatch(getTeamMembers(userData.teamId));
  }, [dispatch, userData]);

  useEffect(() => {
    if (team?.data?.users?.length > 0) {
      const firstUser = team.data.users[0];
      setTeamMember(firstUser.linkedinId);
      handleTeamMemberChange(firstUser.linkedinId);
    }
  }, [team]);

  const handleTeamMemberChange = (newTeamMember) => {
    console.log("Selected Team Member:", newTeamMember);
    const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
    const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);
    setTeamMember(newTeamMember);
    dispatch(setUserId(newTeamMember));
    dispatch(totalLikesAndComments(newTeamMember));
    dispatch(fetchAnalyticsData(
      newTeamMember,
      formattedStartDate,
      formattedEndDate
    ));
  };

  const mainContent = (
    <div
      className={`flex flex-col gap-12 py-12 mb-24 ${layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"}`}
      style={{ overflowY: "auto" }}
      id="hide-scrollbar"
    >
      <div className="flex justify-end -mt-6 items-center gap-2">
        <p className={isDark ? "black" : "white"}>Select a team member to see analytics:</p>
        <div className="w-44">
          <Select value={teamMember} onValueChange={handleTeamMemberChange}>
            <SelectTrigger
              className={`px-2 py-1 rounded-lg outline-none custom-select ${isDark ? "bg-transparent border border-black text-black" : "bg-transparent border border-white text-white"}`}
            >
              <SelectValue placeholder="Select Team Member" />
            </SelectTrigger>
            <SelectContent className={isDark ? "bg-white text-black" : "bg-black text-white"}>
              {team?.data?.users && Array.isArray(team?.data?.users) && team?.data?.users.length > 0 ? (
                team?.data?.users.map((member) => (
                  <SelectItem key={member.linkedinId} value={member.linkedinId}>
                    {member.userName}
                  </SelectItem>
                ))
              ) : (
                <SelectItem value="no-team">No team members available</SelectItem>
              )}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex max-md:flex-col max-md:px-50 md:justify-between md:px-20">
        <PostSummeryBox icon={isDark ? "post_created" : "post_created-white"} name={"Posts Created"} dataKey="postCount" />
        <PostSummeryBox icon={isDark ? "post_scheduled_small" : "post_scheduled_small-white"} name={"Total Likes"} dataKey="totalLikes" />
        <PostSummeryBox icon={isDark ? "post_draft" : "post_draft-white"} name={"Total Comments"} dataKey="totalComments" />
      </div>
      <BarChartComp />
      <div className="flex flex-col gap-4">
        <h3 className="text-xl">Top Interactions</h3>
        <div className={`${isDark ? "bg-white" : "bg-[#2D2D2D]"} p-6 rounded-lg`}>
          <TopInteractors />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="text-xl">Consistency Tracking</h3>
        <div className={`${isDark ? "bg-white" : "bg-[#2D2D2D]"} p-6 rounded-lg`}>
          <StreakComp />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="text-xl">Engaging Time Tracking</h3>
        <div className={`${isDark ? "bg-white" : "bg-[#2D2D2D]"} p-6 rounded-lg`}>
          <Table />
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative w-full">
      {isFree && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
          <div className={`${isDark ? "post-ad grediant-border" : "post-ad-dark"}`} style={{ width: "50%", position: "fixed", bottom: "30%" }}>
            <div className="post-box-text">
              <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">
                You have discovered a premium feature. To access all our features, you need to subscribe to one of our plans by clicking the button below.
              </p>
            </div>
            <div className="btn-create-post">
              <Link to="/buy-subscription" className="grediant-button" style={{ background: "linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)" }} title="Upgrade">
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
      <div style={{ filter: isFree ? "grayscale(100%)" : "none", pointerEvents: isFree ? "none" : "auto" }}>
        {mainContent}
      </div>
    </div>
  );
};

export default Analytics;
