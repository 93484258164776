import React, { useEffect, useRef, useState } from "react";
import { patchApi, postApi, getApi } from "../../api/methods";
import { useDispatch, useSelector } from "react-redux";
import { apibase } from "../../api/API";
import { isLoading } from "../../store/features/common/commonSlice";
import { toast } from "react-toastify";
import "./OrderReview.css";
import { useNavigate } from "react-router-dom";
const RAZOR_PAY_KEY_ID = import.meta.env.RAZOR_PAY_KEY_ID;

const OrderReview = () => {
  const { isDark } = useSelector((state) => state.common);
  const cartData = JSON.parse(localStorage.getItem("cartdata"));
  const user = useSelector((state) => state.user);
  // console.log({ user });
  const [showPromo, setPromo] = useState(false);
  const [totalAmount, setTotalAmount] = useState(cartData.cost);
  const [discountAmount, setDiscountAmount] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enteredPromoCode, setEnteredPromoCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [serialNumber, setserialNumber] = useState("");
  // State to store applied discount
  const [discount, setDiscount] = useState(0);
  
  const promoRef=useRef(null)
  // Function to handle promo code input change
  const handlePromoCodeChange = (e) => {
    setEnteredPromoCode(e.target.value);
  };

  const handleClickOutside=(e)=>{
    if(promoRef.current && !promoRef.current.contains(e.target)){
      setPromo(false)
    }
  }

  // Function to apply promo code and calculate discount
  const applyPromoCode = async () => {
    if (cartData?.planType != "Creator") {
      toast.error(
        "This coupon is applicable only for the purchase of Creator plan."
      );
      return;
    }

    try {
      const promoCodeDetails = await getApi(
        `coupon-details/get-coupon/${enteredPromoCode}`
      );
      // console.log("promoCodeDetails")
      console.log(promoCodeDetails.serialNumber);
      // setAppliedCoupon()
      setserialNumber(promoCodeDetails.serialNumber);
      // console.log("promoCodeDetails")

      if (promoCodeDetails.discountType === "value") {
        setDiscountAmount(promoCodeDetails.discountValue);
        setTotalAmount(
          Number(cartData.cost) - Number(promoCodeDetails.discountValue)
        );
      } else if (promoCodeDetails.discountType === "percentage") {
        console.log(cartData.cost);
        console.log(
          (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) / 100
        );
        setDiscountAmount(
          (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) / 100
        );
        setTotalAmount(
          Number(cartData.cost) -
            Number(
              (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) /
                100
            )
        );
        // cartData?.planDuration === 'Yearly' ? cartData.cost * 12 : cartData.cost
      }
      if (cartData.planDuration === "Yearly") {
        setDiscountAmount(discountAmount * 12);
      }

      setAppliedCoupon(enteredPromoCode);
      toast.success("Coupon Code has been Applied");
    } catch (error) {
      console.log(error);
      setEnteredPromoCode("");
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      }
      // Check if the error response has a status code of 400
      else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while applying the coupon.");
      }
      // toast.error('Invalid promo code!');
    }
  };

  // Calculate total amount after discount
  const calculateTotalAmount = () => {
    // Calculate total amount without discount
    let totalAmount =
      cartData?.planDuration === "Yearly"
        ? cartData?.cost * 12
        : cartData?.cost;
    // Apply discount
    totalAmount -= (totalAmount * discount) / 100;
    return totalAmount.toFixed(2); // Round to two decimal places
  };

  const [updButton, setUpdButton] = useState(false);

  const [billingData, setBillingData] = useState({
    pincode: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    email: "",
    name: "",
    address: "",
    UserId: "",
  });
  const [billingUpdData, setBillingUpdData] = useState({
    pincode: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    email: "",
    name: "",
    address: "",
    UserId: "",
  });
  // console.log({ billingUpdData });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // fetch any user billing information
  const getUserBillData = () => {
    getApi(`billing/get-user-billing-data/${user?.userData?.linkedinId}`)
      .then((res) => {
        setBillingUpdData(res.data);
        setBillingData(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  // update any user billing information
  const updateBillingData = () => {
    dispatch(isLoading(true));
    patchApi(
      `billing/update-billing-information/${user?.userData?.linkedinId}`,
      billingData
    )
      .then((res) => {
        dispatch(isLoading(false));
        // console.log(res);
        toast.success("Address updated successfully!");
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.warn(err);
        toast.error("Update failed!");
      });
  };

  // create any user billing information
  const handleBillingData = () => {
    dispatch(isLoading(true));
    postApi("billing/create-billing-information", {
      UserId: user?.userData?.linkedinId,
      address: billingData?.address,
      pincode: billingData?.pincode,
      city: billingData?.city,
      state: billingData?.state,
      country: billingData?.country,
      phone: billingData?.phone,
      email: billingData?.email,
      name: billingData?.name,
    })
      .then((res) => {
        dispatch(isLoading(false));
        // console.log(res);
        toast.success("Address saved successfully!");
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.warn(err);
        toast.error("Something went wrong!");
      });
  };
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // Function to calculate the end date based on the plan duration
    const calculateEndDate = () => {
      // Create a new Date object for the current date
      let newEndDate = new Date();

      // Calculate the new end date based on the plan duration
      if (cartData?.planDuration === "Monthly") {
        newEndDate.setMonth(newEndDate.getMonth() + 1);
      } else if (cartData?.planDuration === "Yearly") {
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
      }

      // Extract day, month, and year from the new end date
      let day = newEndDate.getDate();
      let month = newEndDate.getMonth() + 1; // Months are zero-indexed
      let year = newEndDate.getFullYear();

      // Format the end date as "day-month-year"
      let formattedEndDate = `${day}-${month}-${year}`;

      // Update the state variable with the formatted end date
      setEndDate(formattedEndDate);
    };

    // Call the calculateEndDate function when the cartData.durationType changes
    calculateEndDate();
  }, [cartData?.durationType]);

  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1; // Months are zero-indexed
  let year = currentDate.getFullYear();
  let formattedDate = `${day}-${month}-${year}`;

  const handleTransaction = () => {
    // Check if the user has a teamId
    if (user?.userData?.teamId) {
      // Calculate the payment amount based on the plan duration
      let pay_amnt =
        cartData?.planDuration === "Yearly"
          ? cartData?.cost * 12
          : cartData?.cost;

      // Send a POST request to create transaction data
      postApi("transaction/create-cart-data", {
        userId: user?.userData?.linkedinId,
        plan_Name: cartData.planType,
        pay_amnt: pay_amnt,
        plan_type: cartData?.planDuration,
        credits: cartData.totalCredits,
        No_Ac: cartData.accounts,
        teamId: user?.userData?.teamId,
        planStatus: true,
        endDate: endDate,
        startDate: formattedDate,
        appliedCoupon: serialNumber,
      })
        .then((res) => {
          // Handle successful response
          // updateCreditHistory(res.data)
          // console.log(res);
        })
        .catch((err) => {
          // Handle error
          console.warn(err);
        });
    }
  };
  // console.log('linked',user?.userData?.linkedinId);

  const updateCreditHistory = async (creditInfo) => {
    try {
      const bodyPost = {
        teamId: user?.userData?.teamId,
        name: cartData.planType,
        subscriptionId: creditInfo.subscriptionId,
        userId: user?.userData?.linkedinId,
        crpoint: `${cartData.totalCredits}`,
      };

      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  // update user subscription plan
  const updateUserSubsscription = () => {
    patchApi(`user/user-session`, {
      linkedinId: user?.userData?.linkedinId,
      subscription: true,
    })
      .then((res) => {
        // console.log(res);
        // alert('fgdfgdfgfdg',JSON.stringify(res));
      })
      .catch((err) => {
        console.warn(err);
        // console.log('fgdfgdfgfdg',err);
      });
  };

  // allow team members subscription plan activate
  const allowTeamSubcription = async () => {
    try {
      const bodyPost = {
        teamId: user?.userData?.teamId,
        subscription: true,
      };

      await patchApi("user/team-subscribed", bodyPost);
      toast.success("Plan Activated!");
      navigate("/credit-history");
      // window.location.href = `${IMAGE_URL}/credit-history`
    } catch (error) {
      console.warn("update team subscription:", error);
      toast.error("Something went wrong!");
    }
  };

  const subscriptionId = localStorage.getItem("subscriptionId");
  // const handleUpdateUserSub = async () => {
  //   await patchApi('user/user-session', { linkedinId: user.userData?.linkedinId, subscription: false })
  // }

  // cancel subscription of all team members
  const handleCancelSubscription = async () => {
    if (subscriptionId) {
      await patchApi("transaction/update-subscription", {
        subscriptionId: subscriptionId,
      })
        .then((res) => {
          // console.log(res);
          // handleUpdateUserSub()
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };
  const handlePayment = async () => {
    const order = await postApi("payment/create-order", {
      amount: totalAmount,
    });
    const options = {
      key: RAZOR_PAY_KEY_ID,
      amount: order.amount,
      currency: "USD",
      name: "Growero",
      description: "Test Transaction",
      order_id: order.id,
      handler: function (response) {
        handleSuccessfulPayment(response);
      },
      prefill: {
        name: "Customer Name",
        email: "customer@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleSuccessfulPayment = async (paymentResponse) => {
    if (paymentResponse) {
      try {
        const response = await postApi("payment/verify-payment", {
          razorpay_order_id: paymentResponse.razorpay_order_id,
          razorpay_payment_id: paymentResponse.razorpay_payment_id,
          razorpay_signature: paymentResponse.razorpay_signature,
          transactionId: "1234", // Make sure this exists in your TransactionData table
          userId: user?.userData?.linkedinId,
          amount: 100, // as a number
          currency: "USD",
        });
        console.log("Payment verification response:", response);
      } catch (error) {
        console.error(
          "Payment verification error:",
          error.response?.data || error.message
        );
      }
      handleCancelSubscription();
      updateUserSubsscription();
      allowTeamSubcription();
      handleTransaction();
      localStorage.removeItem("subscriptionId");
    } else {
      toast.error("Payment Cancelled");
    }

    // console.log('Payment successful, proceeding with app logic');
  };

  // buy subscription plan by user
  const checkout = async () => {
    if (totalAmount == 0) {
      try {
        const response = await patchApi(
          `coupon-details/mark-coupon/${appliedCoupon}`,
          { isUsed: true }
        );
        // Handle successful marking of coupon
        console.log("Coupon marked as used:", response);
      } catch (err) {
        // Handle error in marking coupon
        console.warn("Error marking coupon as used:", err);
      }

      handleCancelSubscription();
      updateUserSubsscription();
      allowTeamSubcription();
      localStorage.removeItem("subscriptionId");
      handleTransaction();
      return;
    }
    handlePayment();
  };

  useEffect(() => {
    getUserBillData();
  }, []);

  useEffect(()=>{
    document.addEventListener("mousedown",handleClickOutside)

    return ()=>{
      document.removeEventListener("mousedown",handleClickOutside)
    }
  },[])
  return (
    <>
      <div className="order-review-container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="mb-4">Billing Informations</h2>
            <div className="billing-form border p-4 rounded">
              <div className="input-group mb-3">
                {isDark ? (
                  <span
                    className="input-group-text h-[2.35rem]"
                    id="basic-addon1"
                  >
                    <i className="fa fa-user bg-[#ffff]" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span
                    className="input-group-text h-[2.35rem] bg-[#020617]"
                    id="basic-addon1"
                  >
                    <i className="fa fa-user " aria-hidden="true"></i>
                  </span>
                )}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  name="name"
                  value={billingData?.name ||  user.userData?.userName} 
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group mb-3 ">
                {isDark ? (
                  <span
                    className="input-group-text h-[2.35rem]"
                    id="basic-addon1"
                  >
                    <i
                      className="fa fa-envelope bg-[#ffff]"
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span
                    className="input-group-text h-[2.35rem] bg-[#020617]"
                    id="basic-addon1"
                  >
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </span>
                )}

                <input
                  type="email"
                  className="form-control"
                  placeholder="@email.."
                  name="email"
                  value={billingData?.email || user.userData?.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group mb-3">
                {isDark ? (
                  <span
                    className="input-group-text h-[2.35rem]"
                    id="basic-addon1"
                  >
                    <i
                      className="fa fa-phone-square bg-[#ffff]"
                      aria-hidden="true"
                    ></i>
                  </span>
                ) : (
                  <span
                    className="input-group-text h-[2.35rem] bg-[#020617]"
                    id="basic-addon1"
                  >
                    <i className="fa fa-phone-square" aria-hidden="true"></i>
                  </span>
                )}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone"
                  name="phone"
                  value={billingData?.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-group mb-3">
                    {isDark ? (
                      <span
                        className="input-group-text h-[2.35rem]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-globe bg-[#ffff]"
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="input-group-text h-[2.35rem] bg-[#020617]"
                        id="basic-addon1"
                      >
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </span>
                    )}

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      name="country"
                      value={billingData?.country}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group mb-3">
                    {isDark ? (
                      <span
                        className="input-group-text h-[2.35rem]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-map bg-[#ffff]"
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="input-group-text h-[2.35rem] bg-[#020617]"
                        id="basic-addon1"
                      >
                        <i className="fa fa-map" aria-hidden="true"></i>
                      </span>
                    )}

                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      name="state"
                      value={billingData?.state}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group mb-3">
                    {isDark ? (
                      <span
                        className="input-group-text h-[2.35rem]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-location-arrow bg-[#ffff]"
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="input-group-text h-[2.35rem] bg-[#020617]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-location-arrow"
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}

                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      value={billingData?.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-group mb-3">
                    {isDark ? (
                      <span
                        className="input-group-text h-[2.35rem]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-sort-numeric-desc bg-[#ffff]"
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                      <span
                        className="input-group-text h-[2.35rem] bg-[#020617]"
                        id="basic-addon1"
                      >
                        <i
                          className="fa fa-sort-numeric-desc"
                          aria-hidden="true"
                        ></i>
                      </span>
                    )}

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pin Code"
                      name="pincode"
                      value={billingData?.pincode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              
              {billingUpdData?.name ? (
                updButton === false ? (
                  <button
                    className="btn btn-warning grediant-button w-100"
                    onClick={() => setUpdButton(true)}
                  >
                    edit
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-info grediant-button w-40 m-1"
                      style={{ width: "48%" }}
                      onClick={updateBillingData}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-outline-danger w-40 m-1"
                      style={{ width: "48%" }}
                      onClick={() => setUpdButton(!updButton)}
                    >
                      Cancel
                    </button>
                  </>
                )
              ) : (
                <>
                  <button
                    className="btn btn-primary grediant-button w-100"
                    onClick={handleBillingData}
                  >
                    Save{" "}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <h2 className="mb-4">Item Added in Your Cart</h2>
            <div
              className="cart-view"
              
            >
              {/* Promo code input */}
              <div className="d-flex justify-center items-center" >
                {showPromo ? (
                  <div ref={promoRef}
                    className={`col-md-6 col-sm-12  ${
                      showPromo ? "show-promo-form" : "close-promo-form"
                    }`}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Promo Code"
                        value={enteredPromoCode}
                        onChange={handlePromoCodeChange}
                      />
                      <button
                        className="btn btn-outline-secondary grediant-button h-[2.35rem] "
                        onClick={applyPromoCode}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 col-sm-12 ml-24 mb-3">
                    <h3
                      className={`mb-0 text-xl ${"promo-heading"}`}
                      onClick={() => setPromo(!showPromo)}
                    >
                      Have a promo code?
                    </h3>
                  </div>
                )}
              </div>
              <table className="table table-bordered table-responsive mt-3">
                <tbody>
                  <tr>
                    <td>Plan Name</td>
                    <td>
                      {" "}
                      <b> {cartData?.planType}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. of Acounts</td>
                    <td>{cartData?.accounts}</td>
                  </tr>
                  <tr>
                    <td>Credits</td>
                    <td>{cartData?.totalCredits}</td>
                  </tr>
                  <tr>
                    <td>Plan Duration</td>
                    <td>{cartData?.planDuration}</td>
                  </tr>
                  <tr>
                    <td>Sub Total</td>
                    <td>
                      {cartData?.currencyType}{" "}
                      {cartData?.planDuration === "Yearly"
                        ? cartData.cost * 12
                        : cartData.cost}{" "}
                    </td>
                  </tr>
                  {discountAmount && (
                    <tr>
                      <td>Discount</td>
                      <td>
                        {cartData?.currencyType}
                        {discountAmount}
                      </td>
                      {/* <td>Sub Total</td>
                    <td>{cartData?.currencyType} {cartData?.planDuration === 'Yearly' ? cartData?.cost * 12 : cartData?.cost} </td> */}
                    </tr>
                  )}
                  {/* <tr>
                    <td>Tax</td>
                    <td>{cartData?.currencyType} 0</td>
                  </tr> */}
                  <tr className="table-active">
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      <b>
                        {" "}
                        {cartData?.currencyType}{" "}
                        {cartData?.planDuration === "Yearly"
                          ? totalAmount * 12
                          : totalAmount}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                className="btn btn-info grediant-button w-100"
                onClick={checkout}
              >
                I am Ready to Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderReview;
